
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { FormInput, FormSelect, FormPhone, FormYesNo } from "@/components/forms";
import { Getter } from "vuex-class";
import { Mandate } from "@/store/modules/mandate.store";
import { getMandateCandidates } from "@/utils/model-helpers";
import { TeamFunctions } from "@/store/modules/activity-account.store";

interface VgeContact {
    first_name: string;
    last_name: string;
    email?: string;
    phone?: string;
}

@Component({
    components: {
        FormInput,
        FormYesNo,
        FormSelect,
        FormPhone,
    },
})
export default class NoKeyFormPart extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("mandate/all") mandates!: Mandate[];

    @Prop() value?: VgeContact[] | null;

    @Prop({ default: false }) disabled?: boolean;

    selectedContact = "";

    localValue: VgeContact = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
    };

    get viewingMandate() {
        const mandate = this.mandates.find((mandate) => mandate.activity_id === this.activity.id);

        if (!mandate) {
            return;
        }

        return mandate;
    }

    get mandateRenters() {
        if (!this.viewingMandate || !this.viewingMandate.details || !this.viewingMandate.details.article_2_keys_available === null || this.viewingMandate.details.article_2_keys_available) {
            return [];
        }

        return [
            {
                first_name: this.viewingMandate.details.article_2_keys_available_holder_first_name ?? "",
                last_name: this.viewingMandate.details.article_2_keys_available_holder_last_name ?? "",
                email: this.viewingMandate.details.article_2_keys_available_holder_email ?? "",
                phone: this.viewingMandate.details.article_2_keys_available_holder_phone ?? "",
            },
        ];
    }

    get mandateCandidates() {
        if (!this.viewingMandate) {
            return [];
        }

        return getMandateCandidates(this.viewingMandate);
    }

    get contacts() {
        const employers = this.activity.assigned_to.filter((account: ActivityAssignedTo) => [TeamFunctions.backoffice, TeamFunctions.seller, TeamFunctions.buyer, TeamFunctions.seller].includes(account.type));

        // make the array unique based on values
        return [
            ...employers.map((employer) => ({
                label: `${employer.first_name} ${employer.last_name}`,
                value: `employer_${employer.first_name}_${employer.last_name}_${employer.email}`,
            })),
            ...this.mandateCandidates.map((candidate) => ({
                label: `${candidate.first_name} ${candidate.last_name}`,
                value: `candidate_${candidate.first_name}_${candidate.last_name}_${candidate.email}`,
            })),
            ...this.mandateRenters.map((renter) => ({
                label: `${renter.first_name} ${renter.last_name}`,
                value: `renter_${renter.first_name}_${renter.last_name}_${renter.email}`,
            })),
            { label: "Andere", value: "other" },
        ].filter((contact, index, self) => self.findIndex((t) => t.value === contact.value) === index);
    }

    handleOfficeOrOtherChanged() {
        if (!this.selectedContact) {
            this.localValue = {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
            };

            this.handleInput();
            return;
        }

        if (`${this.selectedContact}`.startsWith("employer_")) {
            const employeeHandle = `${this.selectedContact}`.replace("employer_", "");
            const employee = this.activity.assigned_to.find((candidate) => `${candidate.first_name}_${candidate.last_name}_${candidate.email}` === employeeHandle);
            if (employee) {
                this.localValue = {
                    first_name: employee.first_name,
                    last_name: employee.last_name ?? "",
                    email: employee.email,
                    phone: employee.phone ?? "",
                };
            }

            this.handleInput();
            return;
        }

        if (`${this.selectedContact}`.startsWith("renter_")) {
            const renterHandle = `${this.selectedContact}`.replace("renter_", "");
            const renter = this.mandateRenters.find((r) => `${r.first_name}_${r.last_name}_${r.email}` === renterHandle);
            if (renter) {
                this.localValue = {
                    first_name: renter.first_name,
                    last_name: renter.last_name ?? "",
                    email: renter.email,
                    phone: renter.phone ?? "",
                };
            }

            this.handleInput();
            return;
        }

        if (`${this.selectedContact}`.startsWith("candidate_")) {
            const candidateHandle = `${this.selectedContact}`.replace("candidate_", "");
            const candidate = this.mandateCandidates.find((candidate) => `${candidate.first_name}_${candidate.last_name}_${candidate.email}` === candidateHandle);
            if (candidate) {
                this.localValue = {
                    first_name: candidate.last_name,
                    last_name: candidate.first_name,
                    email: candidate.email,
                    phone: candidate.phone,
                };
            }

            this.handleInput();
            return;
        }

        this.localValue = {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
        };

        this.handleInput();
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: VgeContact | null) {
        this.localValue = newValue ?? {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
        };
    }
}
